<template>
	<div class="account">
		<div class="account-top">
			<div class="account-top-avatar">
				<el-avatar :src="userInfo.avatar==null?'/img/kasuie/avatar_default.png':userInfo.avatar" class="account-top-avatar-el"></el-avatar>
			</div>
			<div class="account-top-upload">
				<p class="account-top-upload-title">上传新头像</p>
				<el-upload
				  class="upload-demo"
				  action=""
				  :disabled="updateAvatarTime"
				  :on-change="changeUpload"
				  :show-file-list="false"
				  :auto-upload="false">
				  <el-button class="setting-btn" :disabled="updateAvatarTime" size="mini" type="primary">点击上传</el-button>
				  <div slot="tip" class="el-upload__tip">允许的最大文件大小为200KB。每三天可改一次。</div>
				</el-upload>
			</div>
		</div>
		<div class="account-middle">
			<div>
				<p><span>用户ID：</span><span v-text="userInfo.uid"></span></p>
				<p><span>权限level：</span><span v-text="userInfo.level"></span></p>
			</div>
			<p><span>用户名：</span><el-input @change="changeMsg()" placeholder="请输入内容" v-model="userInfo.username" clearable size="mini"></el-input></p>
			<p><span>性别：</span>
				<template>
					<el-radio v-model="userInfo.gender" :label="1" @change="changeMsg()">男</el-radio>
					<el-radio v-model="userInfo.gender" :label="0" @change="changeMsg()">女</el-radio>
				</template>
			</p>
			<p><span>电子邮箱：</span><el-input placeholder="请输入内容" v-model="userInfo.email" @change="changeEmail()" clearable size="mini"></el-input><el-button v-if="userInfo.verify==0&&!change" @click="verifyEmail()" class="setting-btn send-code" type="primary" size="mini" round>验证邮箱</el-button></p>
			<p v-if="change"><span>验证码：</span><el-input placeholder="请输入内容" v-model="userInfo.code" class="user-code" clearable size="mini"></el-input><el-button class="setting-btn send-code" type="primary" :disabled="count!=0" @click="verifyEmail()" size="mini" round>获取验证码<span v-if="count!=0" v-text="'('+count+')'"></span></el-button></p>
			<p><span>个人网站：</span><el-input placeholder="请输入内容" @change="changeMsg()" v-model="userInfo.site" class="user-site" clearable size="mini"><template slot="prepend">http://</template></el-input></p>
			<p><span>生日：</span><el-date-picker @change="changeMsg()" v-model="userInfo.birthday" class="user-birthday" size="mini" type="date" placeholder="选择日期"></el-date-picker></p>
		</div>
		<div class="account-bottom" v-if="changed">
			<el-row>
			  <el-button class="setting-btn" type="primary" @click="submit()" round>提交</el-button>
			  <el-button class="setting-btn" type="info" @click="cancel()" round>取消</el-button>
			</el-row>
		</div>
		<Cropper ref="cropper" :uid='userInfo.uid'></Cropper>
	</div>
</template>

<script>
import Cropper from '@/components/Cropper.vue'
export default {
	inject:['reload'],
	components: {
		Cropper
	},
	data() {
		return {
			change: false,
			userInfo: {
				code: '',
				site: '',
				birthday: '',
			},
			updateAvatarTime: true,
			resetUser: '',
			email: '',
			fileList: [],
			count: 0,
			changed: false,
		}
	},
	methods:{
		async getUser() {
			let that = this
			that.userInfo = {
				...that.userInfo,
				...that.$store.getters.getUser
			}
			let url = 'getUser?uid='+that.userInfo.uid
			await this.$axios.get(url).then(function(results){
				that.userInfo = results.data
				that.email = results.data.email
			},function(err){
				console.log(err)
			})
		},
		getData() {
			if(!this.$cookies.isKey("updateAvatarTime")){
				this.updateAvatarTime = false
			}
			if(this.userInfo.level>1){
				this.updateAvatarTime = false
			}
		},
		handleChange(file, fileList) {
			this.fileList = fileList.slice(-3);
		},
		changeUpload(val){
			this.$refs.cropper.changeUpload(val)
		},
		//判断是否信息改变
		changeMsg(){
			this.changed = true
		},
		checkEmail(){
			if(this.userInfo.email != ''){
				let rgx = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
				if(this.userInfo.email.match(rgx)==null){
					return "1"
				}
				return "0"
			}else{
				return "2"
			}
		},
		changeEmail(){
			if(this.checkEmail()=="2"){
			    this.$message({
			        type: 'error',
			        message: '邮箱不能为空'
			    });
				this.userInfo.email = this.email
			}else if(this.checkEmail()=="1"){
				this.$message({
				    type: 'error',
				    message: '请输入正确的邮箱'
				});
				this.userInfo.email = this.email
			}else{
				if(!this.change){
					this.$confirm('修改邮箱需要需重新验证邮箱, 是否继续?', '提示', {
					  confirmButtonText: '确定',
					  cancelButtonText: '取消',
					  type: 'warning'
					}).then(() => {
						this.change = true
						this.verifyEmail()
					}).catch(() => {
						this.userInfo.email = this.email
					});
				}				
			}
		},
		verifyEmail(){
			let that = this
			this.change = true
			that.changed = true
			that.count = 60
			that.$axios.get('mail?uid='+that.userInfo.uid+'&email='+that.userInfo.email)
			.then(function(result){
				if(result.data){
					let timer = setInterval(() => {
						if(that.count<=0){
							clearInterval(timer)
						}else{
							that.count--
						}
					},1000)
				}else{
					that.count = 0
					that.$notify.error({
						title: '警告',
						message: '获取验证码失败!'
					});
				}
			},function(err){
				console.log(err);
			})
		},
		cancel(){
			this.changed = false
			this.change = false
			this.getUser()
		},
		submit(){
			let that = this
			that.$axios.post("updateUser",that.userInfo).then(
			function(res){
				if(res.data.code=='0'){
					that.$notify.error({
						title: '失败',
						message: '账号信息错误，请联系博主解决！'
					});
				}else if(res.data.code=='1'){
					that.$notify.error({
						title: '失败',
						message: '验证码错误！'
					});
				}else{
					that.$notify({
						title: '成功',
						message: '更新资料成功',
						type: 'success'
					});
					that.reload()
				}
			},function(err){
				console.log(err);
			})
		}
	},
	created() {
		this.getUser()
		this.getData()
	}
}
</script>

<style scoped lang="scss">
.account {
	.setting-btn{
		background: var(--g-01);
	}
	&-top {
		text-align: start;
		display: flex;
		&-avatar {
			display: inline-block;
			&-el{
				width: 100px;
				height: 100px;
			}
		}
		&-upload{
			position: relative;
			width: 100%;
			padding: 5px 2em;
			display: inline-block;
			&-title{
				margin: 5px 0;
			}
			.upload-demo{
				position: absolute;
				bottom: 5px;
				text-align: start;
				::v-deep .el-upload{
					width: unset;
					margin-bottom: .5em;
				}
			}
		}
	}
	&-middle{
		>div{
			text-align: start;
			>p{
				width: 40%;
				margin: 2em 1em 0 1em;
				font-size: var(--m);
				letter-spacing: 0.09em;
				box-sizing: border-box;
				color: var(--m-03);
				display: inline-block !important;
			}	
		}
		>p{
			position: relative;
			margin: 2em 1em;
			font-size: var(--m);
			letter-spacing: 0.09em;
			box-sizing: border-box;
			color: var(--m-03);
			span:nth-child(1){
				text-align: start;
				width: 7em;
			}
			span:nth-child(2){
			}
			.el-input{
				width: 80%;
				::v-deep .el-input__inner{
					color: white;
					border: 1px solid #abaeb7;
					border-radius: 0.8rem;
					background-color: rgba(255,255,255,.5);
				}
			} 
			.user-birthday{
				width: 80%;
			}
			.user-site ::v-deep{
				.el-input__inner{
					border-left: none;
					border-radius: 0 12px 12px 0;
				}
				.el-input-group__prepend{
					color: white;
					padding: 0 0 0 20px;
					border: 1px solid #abaeb7;
					border-right: none;
					border-radius: 12px 0 0 12px;
					background-color: rgba(255, 255, 255, 0.5);
				}
			}
			.send-code{
				position: absolute;
				right: 0;
				border-radius: .8rem;
			}
		}
	}
}
</style>
