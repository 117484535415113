<template>
	<div class="website">
		<div class="general">
			<h2 class="title">General</h2>
			<div class="toggle_options">
				<div class="option">
					<div class="toggle" @click="activateToggle"></div>
					<p>Newsletter subscribe</p>
				</div>
				<div class="option" @click="activateToggle">
					<div class="toggle off"></div>
					<p>Keep in background when closing the window</p>
				</div>
				<div class="option" @click="activateToggle">
					<div class="toggle off"></div>
					<p>Show in Applications bar</p>
				</div>
				<div class="option" @click="activateToggle">
					<div class="toggle"></div>
					<p>Allow comments</p>
				</div>
			</div>
		</div>
		<div class="appearance">
			<h2 class="title">Appearance</h2>
			<div class="appearance_options">
				<div class="option">
					<p class="text">Font Size</p>
					<div class="font_size">
						<p class="letter size_s" @click="activateLetter"><span>A</span>
						</p>
						<p class="letter size_m select" @click="activateLetter"><span>A</span>
						</p>
						<p class="letter size_l" @click="activateLetter"><span>A</span>
						</p>
					</div>
				</div>
				<div class="option">
					<p class="text">Theme</p>
					<div class="themes">
						<div class="color_circle"><span class="color c_purple active_color" @click="changeTheme"></span>
						</div>
						<div class="color_circle"><span class="color c_green" @click="changeTheme"></span></div>
						<div class="color_circle"><span class="color c_blue" @click="changeTheme"></span></div>
						<div class="color_circle"><span class="color c_pink" @click="changeTheme"></span></div>
						<div class="color_circle"><span class="color c_orange" @click="changeTheme"></span></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			
		}
	},
	methods:{
		activateToggle(event) {
		    var currentToggle = event.target;
			if (currentToggle.classList.contains('off')) {
				currentToggle.classList.remove('off');
			} else {
		        currentToggle.classList.add('off');
		    }
		},
		changeTheme(event) {
		    var currentColor = event.target,
		        allColors = document.querySelectorAll('.color');
			var colorPurple = document.querySelector('.c_purple'),
			    colorGreen = document.querySelector('.c_green'),
			    colorBlue = document.querySelector('.c_blue'),
			    colorPink = document.querySelector('.c_pink'),
			    colorOrange = document.querySelector('.c_orange'),
		        container = document.querySelector('.container');
				
		    allColors.forEach(function (el) {
		        el.classList.remove('active_color');
		    });
		    currentColor.classList.add('active_color');

		    if (colorPurple.classList.contains('active_color')) {
		        container.setAttribute('data-theme', '')
		    }
		    if (colorGreen.classList.contains('active_color')) {
		        container.setAttribute('data-theme', 'green')
		    }
		    if (colorBlue.classList.contains('active_color')) {
		        container.setAttribute('data-theme', 'blue')
		    }
		    if (colorPink.classList.contains('active_color')) {
		        container.setAttribute('data-theme', 'pink')
		    }
		    if (colorOrange.classList.contains('active_color')) {
		        container.setAttribute('data-theme', 'orange')
		    }
		},
		activateLetter(event) {
		    var currentLetter = event.currentTarget,
				sizeS = document.querySelector('.size_s'),
				sizeM = document.querySelector('.size_m'),
				sizeL = document.querySelector('.size_l'),
		        allLetters = document.querySelectorAll('.letter'),
				container = document.querySelector('.container');
			allLetters.forEach(function (el) {
		        el.classList.remove('select');
		    });
		    currentLetter.classList.add('select');
			if (sizeS.classList.contains('select')) {
		        container.setAttribute('data-size', 'small')
		    }
		    if (sizeM.classList.contains('select')) {
		        container.setAttribute('data-size', '')
		    }
		    if (sizeL.classList.contains('select')) {
		        container.setAttribute('data-size', 'large')
		    }
		}
	},
	mounted() {}
}
</script>

<style>
	
</style>
