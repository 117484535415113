<template>
	<div class="account">
		<div class="account-top">
			<div class="account-top-avatar">
				<el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
			</div>
			<div class="account-top-upload">
				
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			
		}
	},
}
</script>

<style>
</style>
