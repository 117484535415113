<template>
	<div class="me">
		<Nav></Nav>
		<Settings></Settings>
	</div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Settings from '@/components/Settings.vue'
export default {
	name: 'Me',
	components: {
		Nav,
		Settings
	},
	mounted() {
		document.body.style.overflow='hidden'
		setTimeout(() => {
			this.$store.commit('showLoading')
			document.body.style.overflow=''		
		},2000)
	},
}
</script>

<style>
</style>
